import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const IntegratedConstructionManagement: React.FC = () => {
  return (
    <>
      <SEO
        title="Integrated construction management in a nutshell"
        description="Want to learn more about integrated construction management? We'll tell you about it in a nutshell in this article"
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          What is integrated construction management?
        </Typography>
        <Typography variant="body1">
          With Integral Construction Management software from the company,
          specialists can quickly and professionally fulfill any architectural
          requirement of the customer. The specialists also know how important
          it is to achieve high-quality implementation of architect's design
          standards. In this case, the skills of site managers are in building
          design, building services design, and landscape design. All the
          considered indicators perfectly complement each other and as a result,
          give an excellent effect.
        </Typography>
        <Typography variant="h2" color="blue">
          What is integrated construction management and how does it work?
        </Typography>
        <Typography variant="body1">
          So, it is worth considering how integrated construction project
          management works. First, the test is drawn up or the existing task is
          revised. After that, the current data is calculated. Next, specialists
          need to evaluate integrated risk and value management in construction
          to prepare an award proposal.
        </Typography>
        <Typography variant="body1">
          Because the specialists have a lot of information about the applicants
          and analyze it thanks to Integrated Construction Management software,
          they can help the head and his managers to choose the best applicants
          for the award, indicating the merits of each candidate.
        </Typography>
        <Typography variant="body1">
          The next step is real estate monitoring. This usually occurs in 3
          areas - structural design, engineering, and outdoor facilities.
        </Typography>
        <Typography variant="body1">
          After completing the project, the transition to the next stage is
          carried out - commissioning. This step is determined at the planning
          and construction stage. This phase includes the actual commissioning
          and the priming phase.
        </Typography>
        <Typography variant="body1">
          At each stage, a thorough project inspection for execution defects
          occurs. If corrections are needed, the team is monitoring this area of
          work as well.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/articles/integrated-construction-management/preview.jpg"
            alt="Integrated construction management"
            title="Integrated construction management"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          Service elements and modules
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>Checking the plan</li>
          <li>Invitation to tender</li>
          <li>Analysis of the technical characteristics</li>
          <li>Support in concluding a contract</li>
          <li>Security</li>
          <li>Commissioning and all related items</li>
          <li>Control of errors and defects</li>
        </ol>
        <Typography variant="h2" color="blue">
          Conclusion
        </Typography>
        <Typography variant="body1">
          Project specialists perfectly perform planned repairs at the
          construction site. And also provide a high level of quality in work.
          The customer will be regularly informed about the progress of the work
          until he receives a perfectly constructed building, which will be put
          into operation.
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default IntegratedConstructionManagement
